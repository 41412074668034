import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const InstantwinFollowAndRepostPresentCampaignResultsAndCosts = ({
    location
  }) => {
  const title =
    "インスタントウィンフォロー＆リポスト企画の成果と費用はどれくらい？【事例解説#01】"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="インスタントウィンフォロー＆リポスト企画の成果と費用はどれくらい？【事例解説#01】"
          pagedesc="インスタントウィン実施で得られる成果を実例を用いて解説。フォロー＆リポストキャンペーンを自動化し、手間とコストを抑えてフォロワー獲得と認知拡大に成功したケースをご紹介。費用対効果に悩む担当者向けにおすすめです。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/240210/240210-1.png"
          pagepath="/blog/instantwin-follow-and-repost-present-campaign-results-and-costs"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              インスタントウィンフォロー＆リポスト企画の成果と費用はどれくらい？【事例解説#01】
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2024年2月10日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="インスタントウィン　フォロー＆リポストキャンペーン　成果と費用を大公開"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/240210/240210-1.png"
                className="w-full"
              />
            </figure>
            <h2 id="id1">インスタントウィンでどれくらいの成果が出るの？</h2>
            <p>「できるだけたくさんの潜在顧客との接点を持ちたい」</p>
            <p>「商品やブランドの認知を広げて、魅力を伝えたい」</p>
            <p>
              多くの企業が、このような思いからSNSマーケティングを始めます。しかし、アカウントを開設し数ヶ月投稿した後、放置してしまう企業も少なくありません。このようなケースでは主な理由として、「投稿への反応がない」「運用しても、目標フォロワー数に程遠い」などが挙げられます。
            </p>
            <p>
              しかし、そのような時こそフォロー＆リポストキャンペーンといったイベントを企画し、SNSでのコンテンツ発信の基盤を築くべきです。
            </p>
            <p>
              この記事では、インスタントウィンツールを使ってのフォロー＆リポストキャンペーンで大きな成果をあげた企業様の事例を紹介し、プレゼント企画を通じたフォロワー獲得と認知拡大についてどのような成果が見込めるのかを解説します。
            </p>

            <h2 id="id2">キャンペーン実施の手間やコストは抑えたい…</h2>
            <p>
              ただし、キャンペーンで大きな成果を得られるとしても、手間がかかりすぎたり、ツール導入に多額の費用が必要となってはその効果も薄れてしまいます。この記事では、インスタントウィンキャンペーンを実施する際の導入費用や、手間やコストをどれだけ削減できるかについても説明します。
            </p>
            <p>
              特に、「インスタントウィンキャンペーンのツール導入を検討中」という方々にとって、実際の導入例を参考に費用対効果を判断いただける記事となっています。ぜひご一読ください。
            </p>

            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>資産性のある販促を行いたい企業</li>
              <li>キャンペーン開催でどのような効果が得られるか知りたい方</li>
              <li>クライアントにプレキャンを提案したい広告代理店担当者</li>
              <li>インスタントウィンサービスは必要か迷っている担当者</li>
            </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    インスタントウィンでどれくらいの成果が出るの？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    キャンペーン実施の手間やコストは抑えたい…
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1.キャンペーンの成果と費用
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        成果その1：2ヶ月半で目標の1万フォロワーを達成、その後2万を突破！
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        成果その2：平均インプレッション数も約1.7倍に！
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        成果その3：最大インプレッションは4万を記録！
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        費用：導入目安半年で約50万円は、充分ペイできそう
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    2. 導入にあたってのお悩み
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        商品の認知拡大がしたい
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        どのようなキャンペーン企画にすればいいのか？
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        導入の決め手は料金とサポート体制
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id12" className="toc__link">
                    3. インスタントウィンツール導入後
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        キャンペーン概要
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        キャンペーンのPDCAを回して改善
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    4. PARKLoTの支援内容
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        自動抽選・DM送信のツール提供で業務負荷を削減！
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        無料相談や改善アドバイスで伸びるキャンペーンへ！
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id18" className="toc__link">
                    5. まとめ：自動ツール導入で、手間とコストを抑えてフォロワー増加と認知拡大を実現
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id19" className="toc__link">
                        キャンペーンでフォロワー獲得と認知拡大が同時に可能
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        インスタントウィンキャンペーン実施ならおまかせください
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. キャンペーンの成果と費用</h2>
              <p>まずは、今回の導入事例で得られた成果と、費用を紹介します。</p>
              <p>以下の通り、非常に大きな成果を出すことができました。</p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="キャンペーンの成果と費用"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/240210/240210-2.png"
              />

              <h3 id="id4">成果その1：2ヶ月半で目標の1万フォロワーを達成、その後2万を突破！</h3>
              <p>
                企業公式アカウントでのフォロワー目安は、一般的に1万人とされています。ふと見つけたアカウントのフォロワーが非常に少なく、不審に感じた経験がある方も少なくないでしょう。今回の導入企業様の信頼感を構築する上でも、これだけ短い期間で1万人のフォロワーを獲得したことは大きな成果でした。フォロワーはその後も順調に増加し、なんと2万人を突破しました。
              </p>

              <h3 id="id5">成果その2：平均インプレッション数も約1.7倍に！</h3>
              <p>
                キャンペーンを継続することでフォロワーが増加し、通常の投稿（キャンペーン以外の投稿）のインプレッションも向上させることができました。導入前と比較して、より多くの潜在顧客へ向けて、直接コンテンツ発信ができるようになりました。これはつまり、X(旧Twitter)アカウントを「資産性のあるオウンドメディア」として位置付けることができたということを意味します。
              </p>

              <h3 id="id6">成果その3：最大インプレッションは4万を記録！</h3>
              <p>
                キャンペーン投稿が拡散され、最大4万人ものユーザーに閲覧されました。これは、それだけ多くの潜在顧客へ商品の宣伝ができ、想起植え付けができたという点で高い効果が期待されます。さらに、今回の企業様では繰り返しキャンペーンを実施いただいたので、同じくらい多くのユーザーへ毎週リーチできていたことになります。
              </p>

              <h3 id="id7">費用：導入目安半年で約50万円は、充分ペイできそう</h3>
              <p>
                今回、フォロワー獲得と認知拡大という成果を得るためにかかったツール導入費用は、およそ50万円。その他に、必要に応じて景品代金やバナー制作費用などが必要です。
              </p>
              <p>
                この費用感についてまず他社サービスと比較した場合、かなり手頃な価格帯といえます。使える機能が異なるため純粋に比べるのは難しいですが、毎月PARKLoTでは半年分にあたる金額が必要なサービスよりは、予算の限られた企業が導入しやすい価格なのではないでしょうか。
              </p>
              <p>
                また費用対効果を考えた場合、広告費と作業コストの削減ができるので、経費以上の効果が得られる可能性が高いでしょう。例えば2万フォロワー獲得を広告課金で達成する場合は、今回の費用の4倍（※1）もの広告費がかかってしまうことがあるようです。さらに、達成までに長い時間がかかる可能性もあります。また、4万人のインプレッションがあるということは、約40人にURLをクリックしてもらえ、広告費20万円を節約できる計算になります。
              </p>
              <p>
                キャンペーン後もフォロワーは残るので、コンテンツを効率的に届ける基盤として、アカウントの資産になるのです。
              </p>
              <small>※1  広告でのフォロワー獲得単価を100円とした場合の200万円との比較</small>
              <small>※2 遷移率を0.1%、リスティング広告でのクリック単価を5千円とした場合の20万円との比較</small>
              <p>
                このように素晴らしい結果を出したキャンペーン企画ですが、企業様はツール導入を即決されたわけではありませんでした。次のパートでは導入を検討している時期のお悩みを紹介します。
              </p>
            </div>
            <div>
              <h2 id="id8">2. 導入にあたってのお悩み</h2>

              <h3 id="id9">商品の認知拡大がしたい</h3>
              <p>
                今回の導入企業様の目的は、商品の認知拡大でした。ただし、リスティング広告出稿のような一時的な効果に留まる「残らない」広告ではなく、長期的に運用しながらマーケティング資産として活用できる戦略を望まれていました。そこで、弊社では拡散性に優れたX(旧Twitter)でのキャンペーンをご提案しました。良質な投稿がフォロワー不足で反応を得られないのは非常にもったいないことです。そのため、まずはキャンペーンを通じて聞き手を増やすことをおすすめしたのです。
              </p>

              <h3 id="id10">どのようなキャンペーン企画にすればいいのか？</h3>
              <p>
                しかし、導入企業様はキャンペーン未経験だったため、「企画の内容をどうすればよいか」という点でお困りでした。そこで、企業様の企画案に対して、弊社の豊富な支援経験に基づいたアドバイスを提供しました。例えば景品金額や人数は、多くの担当者が悩むポイントです。また、バナーデザインもキャンペーンの成果に大きく影響するため、導入前の無料相談にて、サポートを行いました。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/amazon-gift-card-vs-quo-card-pay-for-sns-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【法人】AmazonギフトカードとQUOカードPay比較！キャンペーンで成果を出せるデジタルギフトは？
                  </a>
                </p>
              </div>

                <h3 id="id11">導入の決め手は料金とサポート体制</h3>
                <p>
                  最終的には、このような企画運用面でのサポートと、他社と比較して特にお得感のある料金設定が決め手となり、PARKLoTを導入いただくことになりました。プラン開始後は、管理画面の操作方法のレクチャーや、企画改善のアドバイスなど、継続してご支援をしています。
                </p>
                <p>
                  次のパートでは、インスタントウィンツールを導入して実施いただいたキャンペーンについて解説します。
                </p>
            </div>
            <div>
              <h2 id="id12">3. インスタントウィンツール導入後</h2>

              <h3 id="id13">キャンペーン概要</h3>
              <p>今回の企業様では、このようなキャンペーンを開催されました。</p>
              <table>
                <thead>
                  <tr className="font-bold bg-palegrey">
                    <th>　</th>
                    <th>内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold bg-palegrey">実施期間</td>
                    <td>1回7日間のキャンペーンを月に4回開催</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">応募方法</td>
                    <td>フォロー＆リポスト</td>
                  </tr>
                  <tr>
                    <td className="font-bold bg-palegrey">景品</td>
                    <td>
                      A賞 商品現品（郵送）<br />
                      B賞 Amazonギフトカード（当選者のみ、その場で自動DM）
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 id="id14">キャンペーンのPDCAを回して改善</h3>
              <p>
                過去の実績を参考にすることは大切ですが、キャンペーンの成果を最大化させるコツは「自社のフォロワーの反応に合わせて改善を行う」ことです。ビジネスモデルや顧客ターゲット、フォロー後に望むアクションは企業によって様々です。それぞれの企業のフォロワーに合った企画内容に適宜修正を加えていくことで、より多くの参加者を獲得し、認知拡大やその他のビジネス目標を達成することが可能です。
              </p>
            </div>
            <div>
              <h2 id="id15">4.PARKLoTの支援内容</h2>
              <p>
                今回の企業様へは、インスタントウィンツールを利用するプランをご契約いただきました。（オプション追加：ギフト券代理購入）また、弊社よりご相談へのアドバイスも提供しております。
              </p>
              <h3 id="id16">自動抽選・DM送信のツール提供で業務負荷を削減！</h3>
              <p>
                PARKLoTのインスタントウィンツールを使うと、フォロー＆リポストキャンペーンを自動化することができます。具体的には、投稿のポスト、抽選、そして当選の場合の即時DM送信といった一連の作業を、システムがすべて自動で行います。これは忙しいマーケティング担当者の作業コストを大幅に削減するだけではなく、送信ミスなどのエラーの防止にも大いに役立ちます。管理画面では参加者数などの数値を確認できるので、キャンペーンの効果検証や改善の策定にも有効です。
              </p>

              <h3 id="id17">無料相談や改善アドバイスで伸びるキャンペーンへ！</h3>
              <p>
                導入後も、弊社では導入企業様からの景品金額やバナーデザイン、当選DMメッセージ文言に関してのご相談にお答えしました。商品の魅力をより多くのユーザーに知ってもらうデザイン、タイムライン上で目立つための戦略など、具体的なアドバイスを提供することで、大変喜んでいただけました。
              </p>

              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id18">5. まとめ：自動ツール導入で、手間とコストを抑えてフォロワー増加と認知拡大を実現</h2>

              <h3 id="id19">キャンペーンでフォロワー獲得と認知拡大が同時に可能</h3>
              <p>
                今回の導入企業様には、X(旧Twitter)運用とインスタントウィン形式のフォロー＆リポストキャンペーンを通して、目的であったフォロワー獲得と認知拡大を実現いただくことができました。フォロワーに対して商品の魅力をより深く伝えたり、さらにターゲット層を呼び込むようなイベントも開催しやすい土台が出来上がりました。
              </p>

              <h3 id="id20">インスタントウィンキャンペーン実施ならおまかせください</h3>
              <p>
                PARKLoTでは、フォロー＆リポストキャンペーンを自動化できるインスタントウィンツールを提供しています。今回の導入企業様と同じ枠組みでのキャンペーンをご希望の場合は、お問い合わせの際に「ツール利用プランで」とお伝えくださるとスムーズです。
              </p>
              <p>
                またPARKLoTでは、ツールの提供だけでなく、キャンペーンの企画代行やアカウント全体の運用代行も承っております。お気軽にご相談ください。
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  無料相談予約ページ
                </a>
              </p>
            </div>

            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/amazon-gift-card-vs-quo-card-pay-for-sns-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【法人】AmazonギフトカードとQUOカードPay比較！キャンペーンで成果を出せるデジタルギフトは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/ten-things-to-check-for-Increasing-twitter-followers-through-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワー数が伸びない時こそ確認したい10選【プレゼント企画を成功させるには？】
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparing-twitter-lottery-tools"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【Twitter抽選ツール5選】企業アカウントで使いやすい無料・有料ツールがわかる
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default InstantwinFollowAndRepostPresentCampaignResultsAndCosts
